// .reactEasyCrop_CropArea{
//   width: 90% !important;
//   height:  90% !important;
//   border: 2px solid rgba(255,255,255,0.5) !important;
// }


.scaler{
  input[type=range] {
    -webkit-appearance: none;
    width: 100%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: #E5E7EB;
    border-radius: 25px;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 1px 2px #888888;
    border: 1px solid #fff;
    height: 24px;
    width: 24px;
    border-radius: 6px;
    background: #1E75C6;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -10px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #E5E7EB;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #3071a9;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #E5E7EB;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type=range]::-ms-fill-upper {
    background: #E5E7EB;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #E5E7EB;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #E5E7EB;
  }
}