.number-input-container{
    /** down arrow styles */
    .react-tel-input .selected-flag .arrow {
        left:29px !important;
        border: solid #1F2937 !important;
        border-width: 0 2px 2px 0 !important;
        padding: 3px !important;
        transform: rotate(45deg) !important;
        -webkit-transform: rotate(45deg) !important;
        top:0 !important;
        margin-top: 0px !important;

    }

    /** input field styles */
    .react-tel-input .form-control{
        font-size: 16px !important;
        line-height: 24px !important;
        border:none;
        height: auto;
        width: 100%;
        padding-left: 60px !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
    /** drop down country list styles */
    .react-tel-input .country-list {
        border-radius: 6px;
        width: 375px;
        height: 150px;
    }
    
    
    .input-container{

        /** disabled input field styles */
        &--is-disabled{
            .form-control{
                background-color: #F3F4F6 ;
                color: #6B7280;
            }
            .selected-flag{
                background-color: #F3F4F6  !important;
            }
            .flag{
                opacity: 0.3;
            }
        }
    }

}