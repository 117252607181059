@import url('https://rsms.me/inter/inter.css');

@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'remixicon/fonts/remixicon.css';
@import 'react-phone-input-2/lib/style.css';

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';



@layer utilities {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }
     /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
        }
  }
  *{
    font-family: 'Inter', sans-serif;
}

/* Root */
html {
    @media screen and (min-width: 1441px) {
      zoom: 97%;
    }
    @media screen and (max-width: 1440px) {
      zoom: 95%;
    }
    @media screen and (max-width: 1540px) {
      zoom: 93%;
    }
    @media screen and (max-width: 1280px) {
      zoom: 91%;
    }
    background-color:#ECECEC;
  }

.styled-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 2px;
	background-color: #F5F5F5;
}

.styled-scroll::-webkit-scrollbar
{
	width: 5px;
  height:5px;
	background-color: #F5F5F5;
}

.styled-scroll::-webkit-scrollbar-thumb
{
	border-radius: 2px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #E2E8F0;
}

.formBuilderHeight{
    height: calc(100vh - 260px);
}
.formBuilderHeightWithEmployment{
    height: calc(100vh - 340px);
}

input[type="checkbox"]{
    z-index: auto !important;
}

.wrong {
  color:rgba(209, 83, 76, 1)
}

.right {
  color:rgba(46, 46, 46, 1)
}

input[type="radio"] {
  @apply border-2 border-Gray-400
}